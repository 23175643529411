<template>
  <span>
    <v-card tile flat class="charcoal charcoalTileMenu" @click="showExercise">
      <v-sheet class="transparent paper--text">
        <v-card-actions class="link pb-0">
          <template v-if="loggedIn">
            <v-icon
              v-if="isFav"
              color="accent"
              title="Remove From Favorites"
              @click.stop.prevent="removeFavorite(exercise.id)"
            >
              mdi-heart
            </v-icon>
            <v-icon
              v-else
              color="accent"
              title="Add To Favorites"
              @click.stop.prevent="addFavorite(exercise.id)"
            >
              mdi-heart-outline
            </v-icon>
          </template>
          <span
            class="pl-1 body-1"
            @click="showExercise"
            :title="'View Details for ' + exercise.name"
          >
            {{
              exercise.name.length > 32
                ? exercise.name.substr(0, 32) + '..'
                : exercise.name
            }}
          </span>
          <v-spacer />

          <v-icon
            class="mr-1 mt-n1"
            :title="'Dificulty: ' + exercise.dificulty"
            :color="dificultyColor(exercise.dificulty)"
          >
            mdi-checkbox-blank
          </v-icon>
        </v-card-actions>
      </v-sheet>
      <v-card-text v-if="xvy" class="pa-0 mt-1 pl-2 silver--text">
        Author: {{ exercise.author_fname }} {{ exercise.author_lname }}
        <v-spacer />
        Approved: {{ exercise.approved }}
      </v-card-text>
      <v-sheet color="transparent" class="px-1  mb-1 link" v-if="showactions">
        <v-sheet class=" transparent  ">
          <v-card-actions class="pb-0 silver--text">
            <template v-if="hasVariations && !exercise.showDetails">
              <v-checkbox
                class="ml-n2 mb-n1 medium-checkbox-left"
                off-icon="mdi-chevron-down"
                on-icon="mdi-chevron-up"
                dark
                color="silver--text"
                :ripple="false"
                @click.stop.prevent="parseVariations"
                v-model="exercise.showVariations"
              >
                <template v-slot:label>
                  <span :class="textSizeXSmall + ' silver--text ml-n2'">
                    Variations
                  </span>
                </template>
              </v-checkbox>
              <v-badge
                color="progressActive--text"
                offset-y="7"
                offset-x="-12"
                :content="exercise.variations_num"
              />
            </template>
            <v-spacer />
            <template v-if="!exercise.showVariations">
              <v-checkbox
                dark
                :ripple="false"
                class="pl-2 mb-n1 medium-checkbox-right "
                off-icon="mdi-chevron-down"
                on-icon="mdi-chevron-up"
                v-model="exercise.showDetails"
                @click.stop.prevent="parseDetails"
              >
                <template v-slot:label>
                  <span :class="textSizeXSmall + ' silver--text ml-n2'">
                    Details
                  </span>
                </template>
              </v-checkbox>
            </template>
          </v-card-actions>
          <template v-if="hasVariations">
            <v-card-subtitle
              class=" pl-1 py-0 mt-1"
              v-if="exercise.showVariations && !exercise.showDetails"
            >
              <v-list dense class="mt-n6 transparent">
                <v-list-item
                  class="my-n2 "
                  v-for="v in variations"
                  :key="v.id"
                  link
                  @click="showVariation(v.id)"
                >
                  <v-list-item-icon class="mr-2">
                    <BaseLinkIcon />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      :class="textSizeXSmall + ' silver--text'"
                    >
                      {{ v.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-subtitle>
          </template>
          <template v-if="exercise.showDetails && !exercise.showVariations">
            <template v-if="Array.isArray(primary) && primary.length">
              <v-card-subtitle
                class="px-0 py-0 mt-n5"
                v-if="exercise.showDetails"
              >
                <h6 class="paper--text">Primary Muscles</h6>
                <span
                  class="link silver--text"
                  v-for="p in primary"
                  :key="p.muscleid"
                  @click.stop.prevent="$emit('muscle_click', p)"
                >
                  <v-icon x-small class="progressActive--text">mdi-blur</v-icon>
                  {{ p.name }}
                </span>
              </v-card-subtitle>
            </template>
            <template v-if="Array.isArray(secondary) && secondary.length">
              <v-card-subtitle class="px-0 py-0">
                <h6 class="paper--text">Secondary Muscles</h6>
                <span
                  class="link silver--text"
                  v-for="p in secondary"
                  :key="p.muscleid"
                  @click.stop.prevent="$emit('muscle_click', p)"
                >
                  <v-icon x-small class="progressActive--text">mdi-blur</v-icon>
                  {{ p.name }}
                </span>
              </v-card-subtitle>
            </template>
            <template v-if="Array.isArray(stabilizers) && stabilizers.length">
              <v-card-subtitle class="px-0 pt-0">
                <h6 class="paper--text">Stabilizing Muscles</h6>
                <span
                  class="link silver--text"
                  v-for="p in stabilizers"
                  :key="p.muscleid"
                  @click.stop.prevent="$emit('muscle_click', p)"
                >
                  <v-icon x-small class="progressActive--text">mdi-blur</v-icon>
                  {{ p.name }}
                </span>
              </v-card-subtitle>
            </template>
            <template v-if="Array.isArray(equipment) && equipment.length">
              <v-card-subtitle class="px-0 pt-0">
                <h6 class="paper--text">Equipment</h6>
                <span
                  class="link silver--text"
                  v-for="p in equipment"
                  :key="p.equipmentid"
                  @click.stop.prevent="$emit('equipment_click', p)"
                >
                  <v-icon x-small class="progressActive--text">mdi-blur</v-icon>
                  {{ p.name }}
                </span>
              </v-card-subtitle>
            </template>
            <v-sheet class="transparent charcoalTile pa-2 mb-2">
              <v-card-actions class="pa-0">
                <v-spacer />
                <BaseTag
                  v-if="exercise.public == 'Yes'"
                  label="Public"
                  icon="mdi-bullhorn"
                  color="silver"
                  :tagsize="tagsize"
                />
                <BaseTag
                  v-if="exercise.resistance == 'Yes'"
                  label="Resistance"
                  icon="mdi-weight-lifter"
                  color="silver"
                  :tagsize="tagsize"
                />
                <BaseTag
                  v-if="exercise.bilateral == 'Yes'"
                  label="Bilateral"
                  icon="mdi-focus-field-horizontal"
                  color="silver"
                  :tagsize="tagsize"
                />
                <BaseTag
                  v-if="exercise.bodyweight == 'Yes'"
                  label="Bodyweight"
                  icon="mdi-yoga"
                  color="silver"
                  :tagsize="tagsize"
                />
                <BaseTag
                  v-if="exercise.calisthenics == 'Yes'"
                  label="Calisthenics"
                  icon="mdi-human-handsup"
                  color="silver"
                  :tagsize="tagsize"
                />
                <BaseTag
                  v-if="exercise.cardio == 'Yes'"
                  label="Cardio"
                  icon="mdi-run-fast"
                  color="silver"
                  :tagsize="tagsize"
                />
                <v-spacer />
              </v-card-actions>
              <v-card-actions class="pa-0 ml-n2">
                <v-spacer />
                <BaseTag
                  :label="exercise.theforce"
                  color="silver"
                  :tagsize="tagsize"
                />
                <BaseTag
                  :label="exercise.mechanics"
                  color="silver"
                  :tagsize="tagsize"
                />
                <BaseTag
                  :label="exercise.utility"
                  color="silver"
                  :tagsize="tagsize"
                />
                <v-spacer />
              </v-card-actions>
            </v-sheet>
          </template>
        </v-sheet>
      </v-sheet>
      <BaseSnackBar :show="snackbar" :snack="snackText" />
      <v-dialog
        v-model="viewMediaDialog"
        :fullscreen="isPhone"
        width="700"
        overlay-color="#1f2c4c"
        overlay-opacity="70"
        @keydown.esc="cancel"
      >
        <v-card
          flat
          color="lightHeader"
          class="d-flex flex-column noscroll"
          height="100%"
        >
          <v-spacer />
          <v-img
            :max-height="imageSize"
            :src="exercise.image"
            :alt="exercise.name"
            :title="exercise.name"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="accent lighten-4" />
              </v-row>
            </template>
          </v-img>
          <v-spacer />
          <v-card-actions class="px-0">
            <v-spacer />
            <BaseActionButton
              icon="mdi-close"
              label="Cancel"
              color="info"
              plain
              :large="!isPhone"
              @clickedThis="viewMediaDialog = false"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="viewInstructions"
        :fullscreen="isPhone"
        width="700"
        overlay-color="#1f2c4c"
        overlay-opacity="70"
        @keydown.esc="cancel"
      >
        <v-card
          flat
          color="lightHeader"
          class="d-flex flex-column noscroll-x"
          height="100%"
        >
          <v-card-actions v-if="isPhone" class="px-0 pb-0">
            <v-spacer />
            <BaseActionButton
              icon="mdi-close"
              label="Cancel"
              color="info"
              plain
              :large="!isPhone"
              @clickedThis="viewInstructions = false"
            />
          </v-card-actions>
          <v-card-text class="pt-2 paper lighten-1">
            <p :class="textSizeSmall">{{ exercise.description }}</p>
            <span v-html="exercise.instructions" class="mt-10"></span>
          </v-card-text>
          <v-spacer />
          <v-card-actions class="px-0">
            <v-spacer />
            <BaseActionButton
              icon="mdi-close"
              label="Cancel"
              color="info"
              plain
              :large="!isPhone"
              @clickedThis="viewInstructions = false"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showExerciseInfo"
        :fullscreen="isPhone"
        :width="dialogWidth"
        overlay-color="charcoal"
        overlay-opacity=".97"
      >
        <ExerciseInfo
          :exerciseid="selectedid"
          @keydown.esc="cancelInfo"
          @cancel="cancelInfo"
          @deleted="deleted"
          @swipe_off="cancelInfo"
        />
      </v-dialog>
    </v-card>
  </span>
</template>
<script>
import axios from 'axios'
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'

const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')
export default {
  components: { ExerciseInfo },
  mixins: [util],
  data: () => ({
    selectedid: null,
    loaded: false,
    show: false,
    tagsize: 'small',
    primary: [],
    secondary: [],
    stabilizers: [],
    equipment: [],
    variations: [],
    hasVariations: false,
    viewMediaDialog: false,
    viewInstructions: false,
    showExerciseInfo: false,
    switchNum: 0,
    favs: [],
    snackbar: false,
    snackText: '',
    timeout: 2000,
    medium: true,
    sharedExercise: null
  }),
  watch: {
    showExerciseInfo: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
    this.loaded = true
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },

  beforeMount() {
    this.hasVariations = this.exercise.variations_num > 0
  },
  props: {
    showactions: {
      type: Boolean,
      default: true
    },
    exercise: {
      type: Object,
      required: true
    }
  },
  computed: {
    isFav: {
      get() {
        return this.exercise.is_favorite == 'Yes'
      },
      set() {
        return this.exercise.is_favorite == 'Yes'
      }
    },
    imageSize() {
      return this.isPhone ? 350 : 500
    },
    ...appConfig
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showExerciseInfo) this.showExerciseInfo = false
      }
    },
    parseVariations() {
      if (
        this.hasVariations &&
        this.exercise.showVariations &&
        this.variations.length == 0
      )
        this.variations = JSON.parse(this.exercise.variations)
    },
    parseDetails() {
      if (this.equipment.length == 0 && this.exercise.equipment != null)
        this.equipment = JSON.parse(this.exercise.equipment)

      if (this.exercise.muscles != null) {
        let muscles = JSON.parse(this.exercise.muscles)

        this.primary = muscles
          ? muscles.filter(function(item) {
              return item.type == 'Primary'
            })
          : null
        this.secondary = muscles
          ? muscles.filter(function(item) {
              return item.type == 'Secondary'
            })
          : null
        this.stabilizers = muscles
          ? muscles.filter(function(item) {
              return item.type == 'Stabilizers'
            })
          : null
      }
    },
    showExercise() {
      if (this.loggedIn) {
        if (this.showactions) {
          this.selectedid = this.exercise.id
          this.showExerciseInfo = true
        } else {
          this.$router.push('/exercises/edit/' + this.exercise.id)
        }
      } else this.$router.push('/exercises/view/' + this.exercise.code)
    },
    showVariation(variationid) {
      this.selectedid = variationid
      this.showExerciseInfo = true
    },
    cancelInfo() {
      this.showExerciseInfo = false
    },
    deleted() {
      this.showExerciseInfo = false
      this.$emit('deleted')
    },

    addFavorite(exerciseid) {
      return axios
        .post(this.baseURL + '/exercises/favorites', {
          exerciseid: exerciseid
        })
        .then(response => {
          if (response.status == 200) {
            this.exercise.is_favorite = 'Yes'
            this.isFav = true

            this.toast(this.exercise.name + ' succesfully added to favorites.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    removeFavorite(exerciseid) {
      return axios
        .delete(
          this.$store.state.config.baseURL +
            '/exercises/favorites/' +
            exerciseid,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            this.isFav = false
            this.exercise.is_favorite = 'No'
            this.toast(
              this.exercise.name + ' succesfully removed from favorites.'
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
